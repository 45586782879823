/* this file is transformed by vux-loader */
/* eslint-disable */
import Vue from 'vue';
import SolToDoList from '@/components/bpm/MyTask/SolToDoList';
import router from '@/router/index';
export default {
  name: 'sol-task',
  data() {
    return {
      searchVal: "",
      solId: ""
    };
  },
  components: {
    SolToDoList
  },
  created() {
    this.solId = this.$route.params.solId;
  },
  methods: {
    search() {
      this.$store.commit('setToDoSearchVal', this.searchVal);
    },
    clear() {
      this.searchVal = "";
      this.$store.commit('setToDoSearchVal', this.searchVal);
    }
  },
  computed: {
    toDoSearch: function () {
      return this.$store.state.searchModule.toDoSearch;
    }
  }
};